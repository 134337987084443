import AgentMainSaleCardV3 from '@/Components/Cards/AgentMainSaleCardV3';
import DotLoader from '@/Components/Loaders/DotLoader';
import styles from '@/styles/MobileStyles/SearchPage.module.scss'; // Create a CSS module for styling
import { customTypesOrder } from '@/utils';
import { Box, Button, Tab, Tabs } from '@mui/material';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import UserListingViewModel from './ListingViewModel';
import MCustomSort from './MCustomSort';
import useCategoryStore from './categoryStore';

const MobileViewHome = () => {
  const limit = 10;
  const options = ['Listings', 'Distress', 'Buyer Requirements'];
  const { listingCategory: categoryOptions, requirementCategory } =
    useCategoryStore();

  const [value, setValue] = useState('Listings');
  const [selectedCategory, setselectedCategory] = useState('Apartment');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const {
    data,
    isFetching,
    handleRefresh,
    setScreen,
    error,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    sortBy,
    setSortBy,
    screen,
  } = UserListingViewModel(selectedCategory || '');

  useEffect(() => {
    if (value === 'Listings') {
      setScreen('seller');
    } else if (value === 'Distress') {
      setScreen('distress');
    } else if (value === 'Buyer Requirements') {
      setScreen('buyer');
    }

    return () => {};
  }, [value]);

  const uniqueItems = data
    ? data?.pages
        .flatMap((page: any) => page.listings)
        .reduce((acc: any[], item: any) => {
          // Check for uniqueness based on some unique identifier like 'id'
          if (!acc.some((existingItem) => existingItem._id === item._id)) {
            acc.push(item);
          }
          return acc;
        }, [])
    : [];
  return (
    <div
      style={{
        padding: '12px 16px',
        backgroundColor: '#1C1C1E',
        minHeight: '100vh',
      }}
    >
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          style={{
            height: '40px',
            minHeight: '40px',
          }}
          TabIndicatorProps={{
            style: {
              height: '3px',
            },
          }}
        >
          {options.map((option, index) => (
            <Tab
              style={{
                fontSize: '12px',
                textTransform: 'capitalize',
                color: option === value ? '#f2f2f2' : '#969696',
                padding: '0 10px',
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                minHeight: '35px',
                height: '35px',
              }}
              disableRipple
              icon={
                index === 1 ? (
                  <Image
                    src={'/assets/icons/mdi_fire.svg'}
                    alt="icon"
                    width={20}
                    height={20}
                    style={{ marginBottom: '0' }}
                  />
                ) : (
                  <></>
                )
              }
              key={index}
              value={option}
              label={option}
            />
          ))}
        </Tabs>
      </Box>

      {categoryOptions && (
        <Box
          sx={{
            width: '100%',
            border: '1px solid #2B2A2F',
            borderRadius: '35px',
            marginTop: '15px',
            backgroundColor: '#161616',
          }}
        >
          <Tabs
            value={selectedCategory}
            onChange={(event, newValue) => setselectedCategory(newValue)}
            scrollButtons="auto"
            variant="scrollable"
            sx={{
              '& .MuiTabs-indicator': {
                display: 'none', // Hide the indicator
              },
              color: '#f2f2f2',
              padding: '3px',
              height: 'max-content',
              minHeight: 'max-content',
            }}
          >
            {(screen === 'buyer' ? requirementCategory : categoryOptions)
              ?.sort(customTypesOrder)
              .map((category, index) => (
                <Tab
                  key={index}
                  label={category}
                  value={category}
                  sx={{
                    borderRadius: '35px',
                    fontWeight: selectedCategory === category ? '500' : '400',
                    height: '100%',
                    minHeight: '100%',
                    textTransform: 'capitalize',
                    fontSize: '12px',
                    flexGrow: 1,
                    minWidth: '120px',
                    color:
                      selectedCategory === category ? '#F2F2F2' : '#969696',
                    backgroundColor:
                      selectedCategory === category ? '#2B2A2F' : 'transparent',
                    '&:hover': {
                      backgroundColor:
                        selectedCategory === category
                          ? '#2B2A2F'
                          : 'rgba(255, 255, 255, 0.1)',
                    },
                    '&.Mui-selected': {
                      backgroundColor: '#2B2A2F',
                      color:
                        selectedCategory === category ? '#F2F2F2' : '#969696',
                    },
                  }}
                />
              ))}
          </Tabs>
        </Box>
      )}
      <MCustomSort sortBy={sortBy} setSortBy={setSortBy} screen={screen} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          justifyContent: 'center',
          width: '100%',
          alignItems: 'center',
          marginTop: '10px',
        }}
      >
        {uniqueItems.map((listing: any, index: number) => (
          <AgentMainSaleCardV3
            distress={listing.distress}
            key={index}
            cardDatas={listing}
          />
        ))}

        {isFetching && !data && <DotLoader />}

        {isFetchingNextPage && <DotLoader />}
        {hasNextPage && !isFetchingNextPage && (
          <Button
            variant="text"
            className={styles.loadMore}
            onClick={fetchNextPage}
          >
            Load More
          </Button>
        )}
      </div>
    </div>
  );
};

export default MobileViewHome;
