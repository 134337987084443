import styles from '@/styles/FieldStyles/FieldStyles.module.scss';
import { Add } from '@mui/icons-material';
import { FunctionComponent, ReactElement } from 'react';
import { isMobile } from 'react-device-detect';
import Select, {
  OptionProps,
  ValueContainerProps,
  components,
} from 'react-select';
import { optionsInt } from '../Home/BHKChoice';
import CheckBoxSvg from '../SVG/CheckBoxSvg';
export type IconProps = {
  width: number;
  height: number;
  color: string;
};

interface TextFieldProps {
  icon: ReactElement<IconProps>;
  options: optionsInt[];
  value: optionsInt;
  label: string;
  isMulti?: boolean;
  isClearable?: boolean | false;
  isOptionDisabled?: boolean;
  dropdown?: boolean;
  isSearchable?: boolean;
  // eslint-disable-next-line no-unused-vars
  setselectValue: (option: optionsInt) => void;
  dark?: boolean;
}
const BtSelectField: FunctionComponent<TextFieldProps> = ({
  icon,
  options,
  value,
  label,
  setselectValue,
  isMulti,
  isClearable = false,
  isOptionDisabled = false,
  dropdown = false,
  isSearchable = true,
  dark = false,
}) => {
  const ValueContainer = ({
    children,
    ...props
  }: ValueContainerProps<optionsInt>) => {
    return (
      <components.ValueContainer {...props}>
        <div
          style={{
            display: 'flex',
            gap: '10px',
            alignSelf: 'center',
            height: '100%',
            alignItems: 'center',
          }}
        >
          {' '}
          {icon}
          {children}
        </div>
      </components.ValueContainer>
    );
  };
  const Option = (props: OptionProps<optionsInt>) => {
    return (
      <components.Option {...props}>
        {' '}
        <div
          style={{
            display: 'flex',
            gap: '10px',
            alignSelf: 'center',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <CheckBoxSvg checked={props.isSelected} width={16} height={16} />{' '}
          {props.children}
        </div>
      </components.Option>
    );
  };
  //@ts-ignore
  const DropdownIndicator = ({ children, ...props }) => {
    const style = { color: '#fff', fontSize: '13px' };
    // console.log(data, "datas");
    return (
      <components.DropdownIndicator
        {...props}
        //@ts-ignore
        style={{
          padding: '0 !important',
          display: 'flex !important',
          alignItems: 'center',
          div: { padding: '0' },
        }}
      >
        <Add style={{ color: '#D12D34' }} />

        {children}
      </components.DropdownIndicator>
    );
  };
  return (
    <div
      className={
        styles[dark ? 'DselectFieldContainer' : 'selectFieldContainer']
      }
      style={{
        minWidth: '200px',
        maxWidth: '100%',
        height: 'max-content',
      }}
    >
      <Select
        MenuProps={{
          disableScrollLock: true,
        }}
        //@ts-ignore
        components={
          dropdown
            ? { ValueContainer, Option, DropdownIndicator }
            : { ValueContainer, Option }
        }
        placeholder={label}
        isSearchable={isSearchable}
        //@ts-ignore
        isOptionDisabled={() => isOptionDisabled}
        instanceId={label + Math.random()}
        isMulti={isMulti ? isMulti : false}
        isClearable={isClearable ? isClearable : false}
        styles={{
          indicatorSeparator: (base) => ({
            ...base,
            display: 'none',
          }),
          //@ts-ignore
          dropdownIndicator: (provided, state) => ({
            ...provided,
            transition: '0.3s ease',
            color: 'var(--sec-text)',
            whiteSpace: 'nowrap',
            transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
          }),
          valueContainer(base, props) {
            return {
              ...base,
              height: isMobile ? '40px' : '54px',
            };
          },
          menu(base, props) {
            return {
              ...base,
              backgroundColor: dark ? '#1C1C1E' : 'var(--sec-text)',
            };
          },
          control(base, props) {
            return {
              ...base,
              height: isMobile ? '40px' : '54px',
              background: dark ? '#2C2C2E' : 'var(--sec-text)',
              border: dark ? '1px solid #39393D' : '1px solid #39393D',
            };
          },
          singleValue: (provided, state) => ({
            ...provided,
            color: '#39393D',
            fontSize: isMobile ? '12px' : '14px',
            whiteSpace: 'nowrap',
          }),
          placeholder: (provided, state) => ({
            ...provided,
            color: '#39393D',
            fontSize: isMobile ? '12px' : '14px',
            whiteSpace: 'nowrap',
          }),
        }}
        menuShouldScrollIntoView={false}
        classNames={{
          control: ({ isFocused }) =>
            !dark
              ? isFocused
                ? styles['selectFieldContainer_selectFocused']
                : styles['selectFieldContainer_select']
              : '',
          option: ({ isFocused, isSelected }) =>
            isSelected
              ? styles['selectFieldContainer_optionMenuSelected']
              : isFocused
              ? styles['selectFieldContainer_optionMenuFocused']
              : styles['selectFieldContainer_optionMenu'],
          menu: () => (dark ? '' : styles.selectFieldContainer_menu),
        }}
        value={isMulti ? value : value.value ? value : null}
        //@ts-ignore
        onChange={(option: optionsInt | null) => {
          if (option !== null) {
            setselectValue(option);
          } else {
            setselectValue({ value: '', label: '' });
          }
        }}
        options={options}
      />
    </div>
  );
};

export default BtSelectField;
