import MainContainer from '@/Components/Containers/MainContainer';
import SearchIconSvgSecond from '@/Components/SVG/SearchIconSvgSecond';
import { useSearchTypeStore } from '@/Stores/zusStores';
import styles from '@/styles/Main/Search/search.module.scss';
import { MobileContext } from '@/utils/DetectMobileContext';
import { SelectChangeEvent } from '@mui/material';
import { useSession } from 'next-auth/react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import TextTransition, { presets } from 'react-text-transition';
import { CloseIcon } from 'yet-another-react-lightbox';
import HomeImage from '../../../../public/assets/Images/main_bg_3.webp';
import HomeImageMob from '../../../../public/assets/Images/mob_bg_2.webp';
import EstimateDrophandler from '../EstimateDrophandler';
import ListingdDrophandler from '../ListingdDrophandler';
import PropertySearchHandler from '../PropertySearchHandler';
import ROISearchHandler from '../ROISearchHandler';
import UnitSearchHandler from '../UnitSearchHandler';
import RentDropDown from './RentDropDown';
import SalesDropdown from './SalesDropdown';
import UserFeedBackUnit from './UserFeedBackUnit';
type SelectedType =
  | 'Sold Property Records'
  | 'Property Rental Records'
  | 'Property Unit History'
  | 'ROI Search'
  | 'Price Insights'
  | 'Buy'
  | 'Rent'
  | 'Agent Listings'
  | 'Agent Listings'
  | 'Agent Requirements';

const HomeSearch = () => {
  // const {
  //   data: locatiopnItems,
  //   error,
  //   isLoading,
  // } = useSWR(`/transaction/top-location-yoy`, fetcher);

  const UserContext = useSession().data?.user;
  const searchOptions: SelectedType[] = [
    'Price Insights',
    'Property Rental Records',
    'Sold Property Records',
    'ROI Search',
  ];
  const [errorUnderline, seterrorUnderline] = useState(false);
  const { isMobile } = useContext(MobileContext);
  const [videoPlay, setvideoPlay] = useState(false);
  const selectedSearch = useSearchTypeStore((state) => state.selectedType);
  const searchKeyword = useSearchTypeStore((state) => state.searchKeyword);
  const setSearchKeyword = useSearchTypeStore(
    (state) => state.setSearchKeyword
  );
  const setSelectedSearch = useSearchTypeStore(
    (state) => state.setSelectedType
  );

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedSearch(event.target.value as SelectedType);
  };
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchKeywordType, setSearchKeyboardType] = useState('');
  const router = useRouter();

  const [openUnitFeedback, setopenUnitFeedback] = useState(false);
  const handleConditionalSearch = async () => {
    if (searchKeyword === '' || !showDropdown) {
      if (selectedSearch === 'Sold Property Records') {
        router.push(`/dubai/trends?keyword=${searchKeyword}`);
      } else if (selectedSearch === 'Property Rental Records') {
        router.push(`/dubai/rental-trends?keyword=${searchKeyword}`);
      } else if (selectedSearch === 'ROI Search') {
        router.push(
          `/dubai/roi-data?type=${searchKeywordType}&keyword=${searchKeyword}&min=${1000}&max=9999999999`
        );
      } else if (selectedSearch === 'Rent') {
        router.push(
          `/search/properties?type=Rent&keyword=${searchKeyword}&keywordtype=${searchKeywordType}`
        );
      } else if (selectedSearch === 'Buy') {
        router.push(
          `/search/properties?type=Buy&keyword=${searchKeyword}&keywordtype=${searchKeywordType}`
        );
      } else if (selectedSearch === 'Agent Listings') {
        router.push(
          `/search/listings?listingType=seller&keyword=${searchKeyword}&keywordtype=${searchKeywordType}`
        );
      } else if (selectedSearch === 'Agent Requirements') {
        router.push(
          `/search/requirements?listingType=buyer&keyword=${searchKeyword}&keywordtype=${searchKeywordType}`
        );
      } else if (selectedSearch === 'Property Unit History') {
        setopenUnitFeedback(true);
        // router.push(`/dubai/unit-trends/${slugify(searchKeyword)}`);
      } else if (selectedSearch === 'Price Insights') {
        if (searchKeywordType === 'l') {
          router.push(`/projects?keyword=${searchKeyword}&keyType=location`);
        }
        if (searchKeywordType === 'p') {
          router.push(`/projects?keyword=${searchKeyword}&keyType=project`);
        }
        if (searchKeywordType === 'd') {
          router.push(`/projects?developer=${searchKeyword}&keyType=developer`);
        }
        // router.push(`/dubai/unit-trends/${slugify(searchKeyword)}`);
      }
    } else {
      seterrorUnderline(true);
    }
  };
  const [firstText, setfirstText] = useState('');
  const [secondaText, setsecondaText] = useState('');
  const [thirdText, setthirdText] = useState('');
  useEffect(() => {
    if (selectedSearch === 'Buy' || selectedSearch === 'Rent') {
      setsecondaText('Find');
      setthirdText('Home');
    } else if (selectedSearch === 'Sold Property Records') {
      setfirstText('Sold Property');
      setsecondaText('Records');
      setthirdText('FIND LATEST TO OLDEST TRANSACTIONS');
    } else if (selectedSearch === 'Property Rental Records') {
      setfirstText('Property Rental');
      setsecondaText('Records');
      setthirdText('FIND LATEST TO OLDEST TRANSACTIONS');
    } else if (selectedSearch === 'Property Unit History') {
      setfirstText('PROPERTY UNIT');
      setsecondaText('History');
      setthirdText('EXPLORE ANY SPECIFIC UNIT IN-DEPTH');
    } else if (selectedSearch === 'ROI Search') {
      setfirstText('Return On');
      setsecondaText('Investment');
      setthirdText('CALCULATED PROFITS');
    } else if (selectedSearch === 'Price Insights') {
      setfirstText('Property Price');
      setsecondaText('Analysis');
      setthirdText('REAL VS. LISTED PRICE');
    } else if (selectedSearch === 'Agent Listings') {
      setsecondaText('Find');
      setthirdText('& Buy');
    } else if (selectedSearch === 'Agent Requirements') {
      setfirstText('Find it.');
      setsecondaText('Sell it');
    }

    if (errorUnderline) {
      seterrorUnderline(false);
    }
    // Use the `content` variable wherever needed in your code
  }, [selectedSearch]);

  useEffect(() => {
    if (showDropdown) {
      seterrorUnderline(false);
    }

    return () => {
      seterrorUnderline(false);
    };
  }, [showDropdown]);

  // logs
  return (
    <MainContainer>
      <div className={styles.searchContainer}>
        {openUnitFeedback && (
          <UserFeedBackUnit
            open={openUnitFeedback}
            setOpen={setopenUnitFeedback}
            project={searchKeyword}
          />
        )}
        <div className={styles.searchContainer_center}>
          <div className={styles.searchContainer_center_bg}>
            <Image
              src={isMobile ? HomeImageMob : HomeImage}
              alt="brokerterminal"
              priority={true}
              loading="eager"
              height={600} // Set the desired height
              className={styles.searchContainer_right_mainimg}
            />
            <div className={styles.searchContainer_center_bg_overlay}></div>
          </div>
          <div className={styles.searchContainer_center_details}>
            <h1>
              <>
                {firstText}{' '}
                <span>
                  {' '}
                  <TextTransition springConfig={presets.wobbly}>
                    {secondaText}
                  </TextTransition>
                </span>
                <br />
                <p>{thirdText}</p>
              </>
              {/* {selectedSearch === 'Buy' || selectedSearch === 'Rent' ? (
              <>
                <span>Find</span> Home{' '}
              </>
            ) : selectedSearch === 'Sold Property Records' ? (
              <>
                Sold Property{'  '}
                <span>Records</span> <br />
                FIND LATEST TO OLDEST TRANSACTIONS
              </>
            ) : selectedSearch === 'Property Rental Records' ? (
              <>
                Property Rental{'  '}
                <span>Records</span> <br />
                FIND LATEST TO OLDEST TRANSACTIONS
              </>
            ) : selectedSearch === 'Property Unit History' ? (
              <>
                PROPERTY UNIT:{'  '}
                <span>History</span> <br />
                EXPLORE ANY SPECIFIC UNIT IN-DEPTH
              </>
            ) : selectedSearch === 'ROI Search' ? (
              <>
                Return On{' '}
                <span>
                  {' '}
                  <TextTransition springConfig={presets.wobbly}>
                    Investment
                  </TextTransition>
                </span>{' '}
                <br />
                CALCULATED PROFITS
              </>
            ) : selectedSearch === 'Price Insights' ? (
              <>
                Property Price {'  '}
                <span>
                  {' '}
                  <TextTransition springConfig={presets.wobbly}>
                    Analysis
                  </TextTransition>
                </span>{' '}
                <br /> REAL VS. LISTED PRICE
              </>
            ) : selectedSearch === 'Agent Listings' ? (
              <>
                Find it. <span>Buy</span> it
              </>
            ) : selectedSearch === 'Agent Requirements' ? (
              <>
                Find it. <span>Sell</span> it
              </>
            ) : (
              ''
            )} */}
            </h1>
            <p>
              {selectedSearch === 'Price Insights'
                ? 'Gain the power to bargain'
                : selectedSearch === 'Property Rental Records'
                ? 'Compare and analyse the market'
                : selectedSearch === 'Property Unit History'
                ? 'Want to know about a specific property? dig here!'
                : selectedSearch === 'Sold Property Records'
                ? 'Gain comprehensive market knowledge before you invest'
                : 'For your smart investment'}
            </p>
            <div
              className={styles.searchContainer_center_details_inputs}
              style={{
                border: errorUnderline ? '1px solid red' : '',
              }}
            >
              <div
                className={
                  styles.searchContainer_center_details_inputs_mainInput
                }
              >
                <div
                  className={
                    styles.searchContainer_center_details_inputs_mainInput_searchIcon
                  }
                >
                  <SearchIconSvgSecond
                    width={isMobile ? 18 : 28}
                    height={isMobile ? 18 : 28}
                    color="var(--primary)"
                  />
                </div>
                <input
                  autoFocus={false}
                  autoComplete="off"
                  type="text"
                  name="search-key"
                  value={searchKeyword}
                  onClick={() => {
                    if (!showDropdown) {
                      setShowDropdown(true);
                    }
                  }}
                  onChange={(e) => {
                    if (!showDropdown) {
                      setShowDropdown(true);
                    }
                    if (errorUnderline) {
                      seterrorUnderline(false);
                    }
                    if (e.target.value === '') {
                      setShowDropdown(false);
                    }
                    setSearchKeyword(e.target.value);
                  }}
                  placeholder={
                    '*Enter a project name'
                    // selectedSearch === 'Property Unit History'
                    //   ? isMobile
                    //     ? 'Explore: Search, Select Maple 1 Unit No.'
                    //     : 'Navigate by Project: Search, Select Property Unit History (e.g., Find Maple 1 Unit No.)'
                    //   : isMobile
                    //   ? 'Search by Project or Location:(e.g Burj Vista 1,Downtown Dubai)'
                    //   : 'Search by Project or Location:(e.g Burj Vista 1,Downtown Dubai)'
                  }
                />
                {searchKeyword && searchKeyword.length > 3 && (
                  <div
                    className={
                      styles.searchContainer_center_details_inputs_mainInput_crossIcon
                    }
                    onClick={() => setSearchKeyword('')}
                  >
                    <CloseIcon style={{ color: 'var(--main-text)' }} />
                  </div>
                )}
                <div
                  className={
                    styles.searchContainer_center_details_inputs_mainInput_searchIconMain
                  }
                  onClick={handleConditionalSearch}
                >
                  {!isMobile ? (
                    <>Search</>
                  ) : (
                    <SearchIconSvgSecond color="white" width={21} height={21} />
                  )}
                </div>
              </div>

              {selectedSearch === 'Sold Property Records' && showDropdown ? (
                <SalesDropdown
                  searchKeyword={searchKeyword}
                  setSearchKeyword={setSearchKeyword}
                  setShowDropdown={setShowDropdown}
                  setSearchKeyboardType={setSearchKeyboardType}
                  type="project"
                />
              ) : selectedSearch === 'Property Rental Records' &&
                showDropdown ? (
                <RentDropDown
                  searchKeyword={searchKeyword}
                  setSearchKeyword={setSearchKeyword}
                  setShowDropdown={setShowDropdown}
                  setSearchKeyboardType={setSearchKeyboardType}
                />
              ) : selectedSearch === 'ROI Search' && showDropdown ? (
                <ROISearchHandler
                  searchKeyword={searchKeyword}
                  setSearchKeyword={setSearchKeyword}
                  setShowDropdown={setShowDropdown}
                  setSearchKeyboardType={setSearchKeyboardType}
                />
              ) : selectedSearch === 'Property Unit History' && showDropdown ? (
                <UnitSearchHandler
                  searchKeyword={searchKeyword}
                  setSearchKeyword={setSearchKeyword}
                  setShowDropdown={setShowDropdown}
                  setSearchKeyboardType={setSearchKeyboardType}
                />
              ) : selectedSearch === 'Buy' && showDropdown ? (
                <PropertySearchHandler
                  searchKeyword={searchKeyword}
                  setSearchKeyword={setSearchKeyword}
                  setShowDropdown={setShowDropdown}
                  setSearchKeyboardType={setSearchKeyboardType}
                  type="Buy"
                />
              ) : selectedSearch === 'Rent' && showDropdown ? (
                <PropertySearchHandler
                  searchKeyword={searchKeyword}
                  setSearchKeyword={setSearchKeyword}
                  setShowDropdown={setShowDropdown}
                  setSearchKeyboardType={setSearchKeyboardType}
                  type="Rent"
                />
              ) : selectedSearch === 'Price Insights' && showDropdown ? (
                <EstimateDrophandler
                  searchKeyword={searchKeyword}
                  setSearchKeyword={setSearchKeyword}
                  setShowDropdown={setShowDropdown}
                  setSearchKeyboardType={setSearchKeyboardType}
                  type="Estimate"
                />
              ) : selectedSearch === 'Agent Listings' && showDropdown ? (
                <ListingdDrophandler
                  searchKeyword={searchKeyword}
                  setSearchKeyword={setSearchKeyword}
                  setShowDropdown={setShowDropdown}
                  setSearchKeyboardType={setSearchKeyboardType}
                  type="Listings"
                />
              ) : selectedSearch === 'Agent Requirements' && showDropdown ? (
                <ListingdDrophandler
                  searchKeyword={searchKeyword}
                  setSearchKeyword={setSearchKeyword}
                  setShowDropdown={setShowDropdown}
                  setSearchKeyboardType={setSearchKeyboardType}
                  type="Requirements"
                />
              ) : null}
            </div>
            {/* <div className={styles.searchContainer_center_details_options}>
              {searchOptions.map(
                (eachSearch: SelectedType | null | undefined) =>
                  //@ts-ignore
                  eachSearch && //@ts-ignore
                  eachSearch !== '' && (
                    <button
                      key={eachSearch}
                      style={{
                        color: '#fff',
                        border:
                          selectedSearch === eachSearch
                            ? '1.5px solid #d12d34'
                            : '1.5px solid #FFF',
                        backgroundColor:
                          selectedSearch === eachSearch
                            ? 'rgba(209, 45, 52, 0.93)'
                            : 'rgba(130, 152, 162, 0.85)',
                      }}
                      onClick={() => {
                        setSearchKeyword('');
                        setSelectedSearch(eachSearch);
                      }}
                    >
                      {eachSearch === 'Price Insights'
                        ? 'Price Insights'
                        : eachSearch}
                    </button>
                  )
              )}{' '}
            </div> */}
          </div>
        </div>

        {/* {!isMobile && (
        <div className={styles.searchContainer_right}>
          <Image
            src={HomeImage}
            alt="brokerterminal" 
            className={styles.searchContainer_right_mainimg}
          />
          <div
            className={styles.searchContainer_right_watch}
            onClick={() => setvideoPlay(true)}
          >
            <div className={styles.searchContainer_right_watch_box}>
              {' '}
              <PlayArrowRounded />
            </div>
            <span>
              Watch <br />
              Video
            </span>
          </div>

          <div className={styles.searchContainer_right_bottom}>
            <Image
              onClick={() => setvideoPlay(true)}
              className={styles.searchContainer_right_bottom_play}
              src={'/assets/icons/play-button.svg'}
              alt="play-video"
              width={50}
              height={50}
            />
            <Image
              className={styles.searchContainer_right_bottom_comma}
              src={'/assets/icons/comma.svg'}
              alt="comma"
              width={20}
              height={20}
            />

            <span>
              We&#x27;re not <br /> just selling homes,
            </span>
            <h3>
              We&#x27;re <span>creating happiness</span>
            </h3>
          </div>
        </div>
      )} */}
        {/* {videoPlay && <VideoPlayer setPlay={setvideoPlay} play={videoPlay} />} */}
      </div>
    </MainContainer>
  );
};

export default HomeSearch;
// aws s3 ls aajproperty/ --recursive | while read -r line;
//        do
//         createDate=$(echo "$line" | awk '{print $1 " " $2}')
//         createDate=$(date -j -f "%Y-%m-%d %H:%M:%S" "$createDate" "+%s")
//         olderThan=$(date -j -v-7d "+%s")
//         if [[ $createDate -lt $olderThan ]]
//            then
//             fileName=`echo $line|awk {'print $4'}`

//             if [[ $fileName != "" ]]
//             then
//                     aws s3 rm s3://aajproperty/$fileName
//             fi
//        fi

//        done;
